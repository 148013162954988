document.addEventListener('DOMContentLoaded', function() {

  // Add event listener on search button
  document.getElementById("searchBtn").addEventListener("click", function(event){
    event.preventDefault();
    document.getElementsByClassName("search-container")[0].classList.toggle("slide-down");
    document.getElementById("search").focus();
  });

  // Display search field on search page
  if (location.pathname == "/search"){
    document.getElementsByClassName("search-container")[0].classList.toggle("slide-down");
    document.getElementById("search").focus();
  }

  // Close alert after  2 seconds
  setTimeout(function(){
    let msg = document.querySelectorAll('.alert.alert-success')[0];
    if (msg) {
      msg.classList.remove('show');
    }
  }, 3000);

}, false);
