var ClipboardJS = require('./clipboard');

document.addEventListener('DOMContentLoaded', function() {

  let entityTypeButton = document.getElementById("entity_entity_type");
  if (entityTypeButton !== null){
    entityTypeButton.addEventListener("change", function (event) {
      if (entityTypeButton.value == "photo_360") {
        document.getElementById("entity_photo_field").classList.remove("d-none");
        document.getElementById("entity_photo_3d_field").classList.remove("d-none");
        document.getElementById("entity_photo").setAttribute('required', true);

        document.getElementById("entity_glb_field").classList.add("d-none");
        document.getElementById("entity_usdz_field").classList.add("d-none");
        document.getElementById("entity_glb").setAttribute('required', false);
        document.getElementById("entity_usdz").setAttribute('required', false);
      } else if (entityTypeButton.value == "object") {
        document.getElementById("entity_glb_field").classList.remove("d-none");
        document.getElementById("entity_usdz_field").classList.remove("d-none");
        document.getElementById("entity_glb").setAttribute('required', true);
        document.getElementById("entity_usdz").setAttribute('required', true);

        document.getElementById("entity_photo_field").classList.add("d-none");
        document.getElementById("entity_photo_3d_field").classList.add("d-none");
        document.getElementById("entity_photo").setAttribute('required', false);
      }
    });
  }

  /*
   * OPTIMIZE -- begin --
   * Find a beeter way to do that
   */
  // Link copy
  // Init clipboard.j btn-clipboards
  var linkBtn = document.getElementById('link-copy');
  if (linkBtn !== null){
    var linkClipboard = new ClipboardJS(linkBtn);
    linkClipboard.on('success', function(e) {
      var linkField = document.getElementById("link");
      linkField.setSelectionRange(0, linkField.value.length);
      var locale = location.pathname.split("/")[1]
      if (locale == "zh-TW") {
        document.getElementById("link-copy").innerHTML = "<i class='far fa-copy'></i> 已複製";
      } else if (locale == "ja") {
        document.getElementById("link-copy").innerHTML = "<i class='far fa-copy'></i> コピーした";
      } else {
        document.getElementById("link-copy").innerHTML = "<i class='far fa-copy'></i> Copied";
      }

      // Set a promise
      var enableCopyBtn = new Promise((resolve, reject) => {
        setTimeout( function() {
          resolve("link-copy");
        }, 2000)
      });
      // Re-enable copy button
      enableCopyBtn.then((btnId)=>{
        document.getElementById(btnId).innerHTML = "<i class='far fa-copy'></i>"
        e.clearSelection();
      });
    });
  }
  // Embed code block copy
  var embedBtn = document.getElementById('embed-copy');
  if (embedBtn !== null){
    var embedClipboard = new ClipboardJS(embedBtn);
    embedClipboard.on('success', function(e) {
      var embedField = document.getElementById("embed");
      embedField.setSelectionRange(0, embedField.value.length);
      var locale = location.pathname.split("/")[1]
      if (locale == "zh-TW") {
        document.getElementById("embed-copy").innerHTML = "<i class='far fa-copy'></i> 已複製";
      } else if (locale == "ja") {
        document.getElementById("embed-copy").innerHTML = "<i class='far fa-copy'></i> コピーした";
      } else {
        document.getElementById("embed-copy").innerHTML = "<i class='far fa-copy'></i> Copied";
      }

      // Set a promise
      var enableCopyBtn = new Promise((resolve, reject) => {
        setTimeout( function() {
          resolve("embed-copy");
        }, 2000)
      });
      // Re-enable copy button
      enableCopyBtn.then((btnId)=>{
        document.getElementById(btnId).innerHTML = "<i class='far fa-copy'></i>"
        e.clearSelection();
      });
    });
  }
  /*
   * OPTIMIZE -- end --
   */
});
